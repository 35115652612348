export const contentTableConfig = {
  // title: '('param.app-application-list')',
  title: '',
  propList: [
    {
      prop: 'terminalId',
      label: 'terminal.id'
    },
    {
      prop: 'sn',
      label: 'terminal.sn'
    },
    {
      prop: 'taskType',
      label: 'terminal.task-type'
    },
    {
      prop: 'requestMessage',
      label: 'terminal.request-message'
    },
    {
      prop: 'responseMessage',
      label: 'terminal.response-message'
    },
    {
      prop: 'status',
      label: 'common.app-status'
    },

    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'general.creation-date'
      // width: '250'
    }
  ],
  showIndexColumn: true
}
